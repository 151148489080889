import React from "react"
import { Row, Cell, Panel } from "../../Layout/Grid"
import ButtonLink from "../../Buttons/ButtonLink"

import { useStaticQuery, graphql } from "gatsby"

import PanelKidsStyle from "./PanelKids.module.styl"

import Img from "gatsby-image"

const PanelKids = () => {
    const queryResult = useStaticQuery(query)
    const data = queryResult.panelKidsJson.panelKids
    const imageData = queryResult.allFile.edges[0].node

    return (
        <Panel extraClasses={PanelKidsStyle.PanelKids}>
            <Row>
                <Cell start="0" end="6" gridRowTp="2" tp={{ start: "1", end: "12" }}>
                    <div className={PanelKidsStyle.PanelLeft}>
                        <div className={PanelKidsStyle.PanelText}>
                            <h2
                                className={`${PanelKidsStyle.PanelTitle} heading-2`}
                            >
                                {data.title}
                            </h2>
                            <p className={PanelKidsStyle.PanelDescription} dangerouslySetInnerHTML={{__html:data.content.description}}>
                            </p>
                        </div>
                        <ButtonLink to={data.cta.url} title={data.cta.title} external={true} />
                    </div>
                </Cell>
                <Cell start="6" end="12" gridRowTp="1" tp={{ start: "1", end: "12" }}>
                    <Img
                        className={PanelKidsStyle.PanelImage}
                        durationFadeIn={1000}
                        fluid={imageData.childImageSharp.fluid}
                        alt={data.content.image.alt}
                    />
                </Cell>
            </Row>
        </Panel>
    )
}

const query = graphql`
    query PanelKidsQuery {
        panelKidsJson {
            panelKids {
                title
                content {
                    description
                    image {
                        src
                        alt
                    }
                }
                cta {
                    url
                    title
                }
            }
        }
        allFile(
            filter: {
                extension: { eq: "jpg" }
                name: { eq: "magicmakers-2018" }
                relativeDirectory: { eq: "assets/images" }
            }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1200) {
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`

export default PanelKids
