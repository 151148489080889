import React, { useRef, useEffect, useContext } from "react"
import { Row, Cell } from "../../Layout/Grid"

import { useStaticQuery, graphql } from "gatsby"
import { CustomContext } from "../../../context/CustomProvider"

import HeroPrimaryStyles from "./HeroPrimary.module.styl"
import ButtonTickets from "../../Buttons/ButtonTickets"

const HeroPrimary = () => {
    const queryResult = useStaticQuery(query)
    const data = queryResult.heroPrimaryJson.heroPrimary

    const heroLogoRef = useRef(null)
    const heroInnerRef = useRef(null)
    const { setTopScrollTriger } = useContext(CustomContext)

    useEffect(() => {
        if (heroLogoRef.current) {
            setTopScrollTriger(heroLogoRef.current.getBoundingClientRect().top)
        }
    }, [heroLogoRef])

    return (
        <div className={HeroPrimaryStyles.HeroPrimary} id="grow">
            <Row>
                <Cell start="0" end="12">
                    <div
                        className={HeroPrimaryStyles.HeroInner}
                        ref={heroInnerRef}
                    >
                        <img
                            ref={heroLogoRef}
                            className={HeroPrimaryStyles.HeroLogo}
                            src={data.image.src}
                            alt={data.image.alt}
                        />
                        <h1 className="is-vis-hidden">GROW 2.5</h1>
                        <p
                            className={`${HeroPrimaryStyles.HeroTeasing} heading-1`}
                        >
                            {data.teasing}
                        </p>
                        <p
                            className={`${HeroPrimaryStyles.HeroTitle} heading-1`}
                        >
                            {data.title}
                        </p>
                        <ButtonTickets />
                    </div>
                </Cell>
            </Row>
        </div>
    )
}

const query = graphql`
    query HeroPrimaryQuery {
        heroPrimaryJson {
            heroPrimary {
                image {
                    alt
                    src
                }
                title
                teasing
            }
        }
    }
`

export default HeroPrimary
