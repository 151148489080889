import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import Icon from "../../Misc/Icon"
import Img from "gatsby-image"

import BoxSpeakerStyle from "./BoxSpeaker.module.styl"

const BoxSpeaker = props => {
    const [isHovering, setIsHovering] = useState(false)
    const [currentImage, setCurrentImage] = useState(0)
    const [touchStart, setTouchStart] = useState(false)
    const savedCallback = useRef()
    const timing = 350

    const renderBoxContent = hasLink => {
        return (
            <>
                <div className={BoxSpeakerStyle.BoxTop}>
                    {props.images &&
                        props.images.length > 0 &&
                        props.images.map((thumbnail, i) => {
                            return (
                                <div
                                    key={`box-speaker-thumbnail-${i}`}
                                    className={`${
                                        i === currentImage
                                            ? BoxSpeakerStyle.BoxImageWrapperVisible
                                            : BoxSpeakerStyle.BoxImageWrapper
                                    }`}
                                >
                                    <Img
                                        alt={props.speaker.alt}
                                        className={BoxSpeakerStyle.BoxImage}
                                        durationFadeIn={1000}
                                        fluid={
                                            thumbnail.node.childImageSharp.fluid
                                        }
                                    />
                                </div>
                            )
                        })}
                </div>
                <div className={BoxSpeakerStyle.BoxBottom}>
                    {props.speaker.hour && (
                        <span className={BoxSpeakerStyle.BoxDate}>
                            {" "}
                            {props.speaker.hour}{" "}
                        </span>
                    )}
                    <span className={BoxSpeakerStyle.BoxAuthor}>
                        {" "}
                        {props.speaker.name}{" "}
                    </span>
                    {hasLink && (
                        <Icon
                            name="arrow-up-left"
                            fill="#000"
                            width={20}
                            height={20}
                            extraClasses={BoxSpeakerStyle.BoxIcon}
                        />
                    )}
                </div>
            </>
        )
    }

    const renderBox = () => {
        const hasLink = props.speaker.link && props.speaker.link.url
        if (hasLink) {
            return (
                <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    title={props.speaker.link.title}
                    href={props.speaker.link.url}
                    className={`${
                        props.full
                            ? BoxSpeakerStyle.BoxSpeakerFull
                            : BoxSpeakerStyle.BoxSpeaker
                    }`}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {renderBoxContent(hasLink)}
                </a>
            )
        }
        return (
            <div
                className={`${
                    props.full
                        ? BoxSpeakerStyle.BoxSpeakerFull
                        : BoxSpeakerStyle.BoxSpeaker
                }`}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onTouchStart={onTouchStart}
            >
                {renderBoxContent(hasLink)}
            </div>
        )
    }

    const loopCallBack = () => {
        if (isHovering && props.images && props.images.length > 0) {
            const next = currentImage + 1
            if (next < props.images.length) {
                setCurrentImage(next)
            } else {
                setCurrentImage(0)
            }
        }
    }

    const onTouchStart = () => {
        setTouchStart(true)
    }

    const onMouseOver = () => {
        if (!isHovering && !touchStart) {
            setIsHovering(true)
        }
    }

    const onMouseOut = () => {
        if (isHovering) {
            setIsHovering(false)
            setCurrentImage(0)
        }
    }

    useEffect(() => {
        savedCallback.current = loopCallBack
    })

    useEffect(() => {
        const tick = () => {
            savedCallback.current()
        }

        let id = setInterval(tick, timing)
        return () => clearInterval(id)
    }, [isHovering])

    return <>{renderBox()}</>
}

BoxSpeaker.propTypes = {
    speaker: PropTypes.shape({
        hour: PropTypes.string,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
        }),
        thumbnail: PropTypes.array,
    }).isRequired,
    full: PropTypes.bool,
    images: PropTypes.array,
}

export default BoxSpeaker
