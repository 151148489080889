import React from "react"
import { Row, Cell } from "../../Layout/Grid"
import BoxSpeaker from "../../Boxes/BoxSpeaker"

import { useStaticQuery, graphql } from "gatsby"

import PanelSpeakersStyle from "./PanelSpeakers.module.styl"

const PanelSpeakers = () => {
    const queryResult = useStaticQuery(query)
    const data = queryResult.panelSpeakersJson.panelSpeakers
    const images = queryResult.allFile.edges

    const renderSpeakersGrid = () => {
        const speakersGrid = []
        const speakersLength = data.speakers.length

        for (let i = 0; i < data.speakers.length; i++) {
            const speaker = data.speakers[i]

            if (speaker.thumbnails.length > 0) {
                const imagesSpeaker = images.filter(
                    edge =>
                        edge.node.name &&
                        edge.node.name.indexOf(speaker.slug) >= 0
                )

                speakersGrid.push(
                    <div
                        className={PanelSpeakersStyle.GridCellFull}
                        key={`panel-speakers-cell-${i}`}
                    >
                        <BoxSpeaker
                            speaker={speaker}
                            full={true}
                            key={`box-speaker-${i}`}
                            images={imagesSpeaker}
                        />
                    </div>
                )
            } else {
                const nextSpeakers = []
                let j = i
                for (j; j < speakersLength; j++) {
                    const nextSpeakerIndex = j + 1
                    if (
                        nextSpeakerIndex < speakersLength &&
                        data.speakers[nextSpeakerIndex].thumbnails <= 0
                    ) {
                        nextSpeakers.push(
                            <BoxSpeaker
                                speaker={data.speakers[nextSpeakerIndex]}
                                key={`box-speaker-${j}`}
                            />
                        )
                    } else {
                        break
                    }
                }

                speakersGrid.push(
                    <div
                        className={PanelSpeakersStyle.GridCell}
                        key={`panel-speakers-cell-${i}`}
                    >
                        <BoxSpeaker
                            speaker={speaker}
                            key={`box-speaker-${i}`}
                        />
                        {nextSpeakers}
                    </div>
                )

                i = j
            }
        }

        return speakersGrid
    }

    return (
        <div
            className={`${PanelSpeakersStyle.PanelSpeakers}`}
            id="program-and-speakers"
        >
            <Row>
                <Cell start="0" end="12">
                    <h2
                        className={`${PanelSpeakersStyle.PanelSpeakersTitle} heading-2`}
                        dangerouslySetInnerHTML={{ __html: data.title }}
                    ></h2>
                </Cell>
            </Row>
            <Row>
                <Cell start="0" end="8">
                    <p
                        className={`${PanelSpeakersStyle.PanelSpeakersTeasing} teasing-2`}
                        dangerouslySetInnerHTML={{ __html: data.teasing }}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell start="0" end="12">
                    <div
                        className={`${PanelSpeakersStyle.GridSpeakers} fade__zone`}
                    >
                        {renderSpeakersGrid()}
                    </div>
                </Cell>
            </Row>
        </div>
    )
}

const query = graphql`
    query PanelSpeakersQuery {
        panelSpeakersJson {
            panelSpeakers {
                title
                teasing
                speakers {
                    name
                    slug
                    hour
                    link {
                        url
                        title
                    }
                    alt
                    thumbnails
                }
            }
        }
        allFile(
            filter: {
                extension: { eq: "jpg" }
                relativeDirectory: { eq: "assets/images/speakers" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1200) {
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`

export default PanelSpeakers
