import React from "react"
import { Row, Cell, Panel } from "../../Layout/Grid"

import { useStaticQuery, graphql } from "gatsby"

import PanelInfoStyles from "./PanelInfo.module.styl"

const PanelInfo = () => {
    const queryResult = useStaticQuery(query)
    const data = queryResult.panelInfoJson.panelInfo

    return (
        <Panel extraClasses={PanelInfoStyles.PanelInfo} id="infos-and-contact">
            <Row>
                <Cell start="0" end="6">
                    <div className={PanelInfoStyles.PanelLeft}>
                        <h2
                            className={`${PanelInfoStyles.PanelTitle} heading-2`}
                        >
                            {data.info.title}
                        </h2>
                        <p
                            className="teasing-2"
                            dangerouslySetInnerHTML={{
                                __html: data.info.description,
                            }}
                        />
                    </div>
                </Cell>
                <Cell start="6" end="12">
                    <div className={PanelInfoStyles.PanelRight}>
                        <h2
                            className={`${PanelInfoStyles.PanelTitle} heading-2`}
                        >
                            {data.contact.title}
                        </h2>
                        {data.contact.links.map((item, index) => {
                            return (
                                <div
                                    className={PanelInfoStyles.BoxContact}
                                    key={`box-contact-${index}`}
                                >
                                    <a
                                        className={`${PanelInfoStyles.BoxLink} link link--secondary`}
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {item.title}
                                    </a>
                                    {item.info && (
                                        <span
                                            className={PanelInfoStyles.BoxInfo}
                                        >
                                            {item.info}
                                        </span>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </Cell>
            </Row>
        </Panel>
    )
}

const query = graphql`
    query PanelInfoQuery {
        panelInfoJson {
            panelInfo {
                info {
                    title
                    description
                }
                contact {
                    title
                    links {
                        title
                        url
                        info
                    }
                }
            }
        }
    }
`
export default PanelInfo
