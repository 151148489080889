class EasterEggs {
    constructor() {
        this.isKonamiMode = false
        this.currentPosition = 0
        this.keycodeSequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]

        this.initialize()
    }

    initialize() {
        this.drawFlag()
        this.bindEvents()
    }

    bindEvents() {
        document.addEventListener("keydown", this.onKeyDown.bind(this))
    }

    drawFlag() {
        console.clear();
        console.log("\n")
        console.log("\n")
        console.log("%c👋", "font-size: 50px")
        console.log("\n")
        console.log(
            "%cJulien a trouvé que mes dessins en ASCII\nn'étaient pas assez bons pour figurer ici.",
            "font-size: 25px; font-weight: bold;font-family: Arial, sans-serif;"
        )
        console.log(
            "%cDésolé.",
            "font-size: 25px; font-family: Arial, sans-serif;"
        )
        console.log("\n")
        console.log("\n")
        console.log("Façonné main et avec beaucoup d'amour chez Spintank.")
        console.log("En collaboration avec ce bon vieux Nicolas Barradeau")
        console.log("www.spintank.fr")
        console.log("\n")
        console.log("\n")
        console.log("%c(Konami)", "font-size: 8px;")
    }

    onKeyDown(e) {
        const currentKeycode = e.keyCode

        if (this.isKonamiMode) {
            return
        }

        if (currentKeycode == this.keycodeSequence[this.currentPosition]) {
            this.currentPosition++

            if (this.currentPosition == this.keycodeSequence.length) {
                this.isKonamiMode = true
                this.drawKonami()
            }
        } else {
            this.currentPosition = 0
        }
    }

    drawKonami() {
        console.log("\n")
        console.log("\n")
        console.log(
            "%cC'était ça comme dessin :",
            "font-size: 15px; font-family: Arial, sans-serif;"
        )
        console.log("\n")
        console.log("\n")
        console.log("                .:/osssssso/:.                ")
        console.log("           `:sdNMMMMMMMMMMMMMMNds:`           ")
        console.log("         /hMMMMMMMMMMMMMMMMMMMMMMMMy:         ")
        console.log("      `+NMMMMMMMMMMMMMMMMMMMMMMMMMMMMN+`      ")
        console.log("     /NMMMMMMMMMMMMMmddddmMMMMMMMMMMMMMN/     ")
        console.log("   `yMMMMMMMMMMms/.        ./smMMMMMMMMMMy`   ")
        console.log("  `dMMMMMMMMMh:                :hMMMMMMMMMd`  ")
        console.log("  hMMMMMMMMm:                    :mMMMMMMMMh  ")
        console.log(" /MMMMMMMMm`                      `mMMMMMMMM/ ")
        console.log(" mMMMMMMMM.                        .MMMMMMMMm ")
        console.log(".MMMMMMMMy                          yMMMMMMMM.")
        console.log("-MMMMMMMMo                          oMMMMMMMM-")
        console.log(".MMMMMMMMy                          yMMMMMMMM.")
        console.log(" mMMMMMMMM.                        .MMMMMMMMm ")
        console.log(" /MMMMMMMMm`                      `mMMMMMMMM/ ")
        console.log("  hMMMMMMMMm:                    :mMMMMMMMMh  ")
        console.log("  `dMMMMMMMMMh:                :hMMMMMMMMMd`  ")
        console.log("   `yMMMMMMMMMMms/.        ./smMMMMMMMMMMy`   ")
        console.log("     /NMMMMMMMMMMMMMmddddmMMMMMMMMMMMMMN/     ")
        console.log("      `+NMMMMMMMMMMMMMMMMMMMMMMMMMMMMN+`      ")
        console.log("         /yMMMMMMMMMMMMMMMMMMMMMMMMy/         ")
        console.log("           `:sdNMMMMMMMMMMMMMMNds:`           ")
        console.log("                .:/oossssoo/:.                ")
        console.log("\n")
        console.log("\n")
        console.log(
            "%cJe dois admettre que ça rend pas hyper bien. 🤷‍♂",
            "font-size: 15px; font-family: Arial, sans-serif;"
        )
        console.log("\n")
        console.log("\n")
    }
}

export default EasterEggs
