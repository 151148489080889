import React, { useContext, useEffect } from "react"

import { CustomContext } from "../context/CustomProvider"

import HeroPrimary from "../components/Hero/HeroPrimary"
import CanvasHome from "../components/Canvases/CanvasHome/CanvasHome.jsx"
import CanvasMask from "../components/Canvases/CanvasHome/CanvasMask.jsx"
import CanvasesHolder from "../components/Canvases/CanvasHome/CanvasesHolder.jsx"
import PanelSpeakers from "../components/Panels/PanelSpeakers"
import PanelKids from "../components/Panels/PanelKids"
import PanelInfo from "../components/Panels/PanelInfo"
import PanelLastEdition from "../components/Panels/PanelLastEdition"
import Footer from "../components/Layout/Footer"
import Metas from "../components/Misc/Metas"

import { TransitionState } from "gatsby-plugin-transition-link"

import EasterEggs from "../misc/EasterEggs"

if (typeof window !== "undefined") {
    new EasterEggs()
}

const IndexPage = props => {
    const { setIsHome } = useContext(CustomContext)

    useEffect(() => {
        setIsHome(true)
    }, [])

    return (
        <>
            <Metas />
            <TransitionState>
                {({ transitionStatus, exit, enter }) => {
                    return (
                        <div
                            className={`container container--home is-${transitionStatus}`}
                        >
                            <CanvasesHolder>
                                {typeof window !== "undefined" && (
                                    <CanvasHome />
                                )}
                                {typeof window !== "undefined" && (
                                    <CanvasMask />
                                )}
                            </CanvasesHolder>
                            <HeroPrimary />
                            <PanelSpeakers />
                            <PanelKids />
                            <PanelLastEdition />
                            <PanelInfo />
                            <Footer />
                        </div>
                    )
                }}
            </TransitionState>
        </>
    )
}

export default IndexPage
