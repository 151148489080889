import React from "react"

import { Row, Cell, Panel } from "../../Layout/Grid"
import AnimatedLink from "../../Misc/AnimatedLink/AnimatedLink"

import PanelLastEditionStyles from "./PanelLastEdition.module.styl"

const PanelLastEdition = () => {
    return (
        <Panel
            extraClasses={`${PanelLastEditionStyles.PanelLastEdition} btn--nav`}
        >
            <AnimatedLink
                to="/gallery"
                className={`${PanelLastEditionStyles.PanelLink}`}
                exit={{
                    duration: 1,
                    scrollDelayAllow: 1,
                    delay: 0,
                    zIndex: 1,
                }}
                entry={{
                    delay: 0,
                    duration: 1,
                    zIndex: 2,
                }}
            >
                <div className={PanelLastEditionStyles.Background}></div>
                <div className={`${PanelLastEditionStyles.Overlay} heading-1`}>
                    <Row>
                        <Cell start="0" end="12">
                            <div
                                className={`${PanelLastEditionStyles.OverlayBottom} heading-1`}
                            >
                                <div
                                    className={
                                        PanelLastEditionStyles.OverlayLeft
                                    }
                                >
                                    <span
                                        className={
                                            PanelLastEditionStyles.OverlayInfo
                                        }
                                    >
                                        Retour sur
                                    </span>
                                    <img
                                        src="./logo-white.svg"
                                        alt="Grow 2018"
                                        className={
                                            PanelLastEditionStyles.OverlayImage
                                        }
                                    />
                                    <span>2018</span>
                                </div>
                                <div
                                    className={
                                        PanelLastEditionStyles.OverlayRight
                                    }
                                >
                                    <div
                                        className={
                                            PanelLastEditionStyles.OverlayTicker
                                        }
                                    ></div>
                                </div>
                            </div>
                        </Cell>
                    </Row>
                </div>
            </AnimatedLink>
        </Panel>
    )
}

export default PanelLastEdition
